import store from '@/store'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    if (params && params.rt) {
      const appLoading = document.getElementById('loading-bg')
      setTimeout(() => {
        if (appLoading) {
          appLoading.style.display = 'block'
        }
      }, 100)

      this.refreshToken(params.rt).then(r => {
        this.isAlreadyFetchingAccessToken = false
        const { data } = r.data

        // Update accessToken in localStorage
        this.setToken(data.accessToken)
        this.setRefreshToken(data.refreshToken)

        localStorage.setItem('userData', JSON.stringify(data.userData))

        this.onAccessTokenFetched(data.accessToken)

        window.location.replace(params.p)
      })
    }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        if (config.url.indexOf('/lkb/') > -1) {
          // Get token from localStorage
          const accessToken = this.getToken()

          // If token is present add it to request's Authorization Header
          if (accessToken) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          delete config.headers.Authorization
        }

        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        if (response && (response.status === 401)) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.removeToken()
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false
              const { data } = r.data

              // Update accessToken in localStorage
              this.setToken(data.accessToken)
              this.setRefreshToken(data.refreshToken)

              this.onAccessTokenFetched(data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }

        if (response && response.data && response.data.data && response.data.data.code === -1) {
          store.dispatch('app/showSwalError', {
            message: response.data.data.msg,
          }).then(() => {
            this.removeToken()
            this.removeRefreshToken()
            this.removeUserData()
            window.location.href = '/'
          })
        }

        if (response && response.data && response.data.data) {
          let message = ''
          if (Array.isArray(response.data.data)) {
            response.data.data.forEach(item => {
              message += `${item.msg} \n`
            })
          } else {
            message = response.data.data.msg
          }

          if (response.data.data.code !== -1) {
            store.dispatch('app/showToastError', {
              message,
              status: response.status,
            })
          }
        }

        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  removeToken() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
  }

  removeUserData() {
    localStorage.removeItem(this.jwtConfig.storageUserDataKeyName)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken(rt) {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      token: rt || this.getRefreshToken(),
    })
  }

  removeRefreshToken() {
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  resetPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.resetPassword, ...args)
  }

  forgotEndpoint(...args) {
    return this.axiosIns.post(this.jwtConfig.forgotEndpoint, ...args)
  }

  verifyLogin(...args) {
    return this.axiosIns.post(this.jwtConfig.verifyLoginEndpoint, ...args)
  }
}
